import React from "react"

import { Star16, StarFilled16 } from "@carbon/icons-react"

const RatingStars = ({ rating, maxrating = 5 }) => {
  let stars = []
  for (let i = 0; i < maxrating; i++) {
    stars.push({ name: "star" })
  }

  return (
    <>
      {stars.map((star, index) =>
        index < Math.round(rating) ? (
          <StarFilled16
            className="inline w-4 h-4 -mt-1 align-middle text-primary"
            key={index}
          />
        ) : (
          <Star16
            className="inline w-4 h-4 -mt-1 text-ruko-gray align-middle"
            key={index}
          />
        )
      )}
    </>
  )
}

export default RatingStars
