import React from "react"
import SbEditable from "storyblok-react"
import RatingStars from "../atoms/RatingStars"
import { StarFilled16 } from "@carbon/icons-react"
import RatingBar from "../atoms/RatingBar"

const ProductRatingOverviewBlock = ({ block }) => {
  let averageRating = 4.1
  return (
    <SbEditable content={block}>
      <section className="py-10 -mb-px border-b border-gray-100">
        <div className="mx-auto max-w-screen-xl px-grid">
          <div className="flex flex-row flex-wrap -mx-grid">
            <div className="w-full md:w-1/2 lg:w-1/4 px-grid">
              <span className="block py-2 font-bold text-primary">
                74 Bewertungen
              </span>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/4 px-grid">
              <div className="py-2 text-sm leading-6 align-baseline">
                <RatingStars rating={averageRating} />
                <span className="ml-1 whitespace-nowrap">
                  {averageRating} Durchschnittsbewertung
                </span>
              </div>
            </div>
            <div className="w-full lg:w-2/4 px-grid">
              <RatingBar
                className="py-2"
                levelName="5"
                totalRatings="74"
                totalLevelRatings="43"
              />
              <RatingBar
                className="py-2"
                levelName="4"
                totalRatings="74"
                totalLevelRatings="16"
              />
              <RatingBar
                className="py-2"
                levelName="3"
                totalRatings="74"
                totalLevelRatings="9"
              />
              <RatingBar
                className="py-2"
                levelName="2"
                totalRatings="74"
                totalLevelRatings="4"
              />
              <RatingBar
                className="py-2"
                levelName="1"
                totalRatings="74"
                totalLevelRatings="2"
              />
            </div>
          </div>
        </div>
      </section>
    </SbEditable>
  )
}

export default ProductRatingOverviewBlock
