import React from "react"

import { StarFilled16 } from "@carbon/icons-react"

const RatingBar = ({
  className,
  totalRatings,
  levelName,
  totalLevelRatings,
}) => {
  let barLength = (totalLevelRatings / totalRatings) * 100 + "%"
  return (
    <div className={`flex flex-row ${className}`}>
      {levelName}
      <StarFilled16 className="inline w-4 h-6 ml-1 align-middle text-primary" />
      <div className="flex items-center flex-grow ml-4">
        <div className="h-1 bg-primary" style={{ width: barLength }}></div>
      </div>
      <span className="inline-block w-12 ml-4 ">{totalLevelRatings}</span>
    </div>
  )
}

export default RatingBar
